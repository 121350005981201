<template>
  <li>
    <div class="flex-column bg-white shadow rounded cursor-move border border-gray-700 m-1" :style="{'visibility': card.fake ? 'hidden' : ''}">
      <div class="flex justify-center p-1">
        <img :src="require( `../assets/${card.icon}`)" :alt="card.name">
      </div>
      <div class="rounded-bottom card-text flex items-center justify-center" :style="{'background-color': card.color}">
          <p class="text-white font-semibold font-sans">{{card.name}}</p>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  props: {
    card: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getCardIcon(card) {      // Return nothing for the default empty string
      return require(`../assets/${card.icon}`);
    }
  },
};
</script>
<style>
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.card-text {
  height: 60px;
}
</style>