<template>
  <div id="app" class="min-h-screen w-screen bg-gray-200 flex-column items-center justify-center" >
    <div class="p-1 flex justify-end">
      <b-button variant="success" @click="goToSecondStep()" :disabled="(!horizontal)">Next Step</b-button>
    </div>
    <horizontal :key="horizontal" :horizontal="horizontal" class="full-screen" :cards="cards" />
  </div>
</template>

<script>
import Horizontal from "./components/Horizontal";
export default {
  name: "App",
  components: {
    Horizontal
  },
  methods: {
    goToSecondStep: function() {
      /*this.cards.forEach(function(card) {
        card.vertical = true;
      });*/
      this.horizontal = false;
    }
  },
  data() {
    return {
      horizontal: true,
      cards: [
        {
          id: 1,
          name: "Acceptance",
          icon: "acceptance.png",
          color: "#ede74e"
        },
        {
          id: 2,
          name: "Curiosity",
          icon: "curiosity.png",
          color: "#f2b143"
        },
        {
          id: 3,
          name: "Freedom",
          icon: "freedom.png",
          color: "#de5041"
        },
        {
          id: 4,
          name: "Goal",
          icon: "goal.png",
          color: "#45347e"
        },
        {
          id: 5,
          name: "Honor",
          icon: "honor.png",
          color: "#50bae2"
        }, 
        {
          id: 6,
          name: "Mastery",
          icon: "mastery.png",
          color: "#78c0ae"
        },
        {
          id: 7,
          name: "Order",
          icon: "order.png",
          color: "#f1c3cf"
        },
        {
          id: 8,
          name: "Power",
          icon: "power.png",
          color: "#a9ae4d"
        },
        {
          id: 9,
          name: "Relatedness",
          icon: "relatedness.png",
          color: "#4dae71"
        },
        {
          id: 10,
          name: "Status",
          icon: "status.png",
          color: "#e484a8"
        }
      ]
    };
  }
};
</script>
<style >
.full-screen {
  min-height:100vh;
  height:100vh;/* or min-height:100vh if you want it to grow */
  display:flex;
}
</style>