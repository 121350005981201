<template>
  <li :key="vertical">
   <draggable
        :list="cards"
        :animation="200"
        ghost-class="moving-vertical-card"
        :group="`card${card.id}`"
        filter=".action-button"
        tag="ul"
        :disabled="(!vertical)"
      >
        <card
          v-for="card in cards"
          :key="card.id"
          :card="card"
        ></card>
      </draggable>
  </li>
</template>
<script>
// import { EventBus } from './../bus/event-bus.js';
import draggable from "vuedraggable";
import Card from "./Card";

export default {
  props: {
    card: {
      type: Object,
      default: () => ({})
    },
    vertical: {
      type: Boolean,
      default: () => (false)
    }
  },
  components: {
    draggable,
    Card
  },
  data() {
    if (!this.vertical)
        return { cards: [ this.card] }
    let cards = []
    for (let i = 0; i < 5; i++) {
        if (i === 2) {
            cards.push(this.card);
            continue;
        }
        const clone = JSON.parse(JSON.stringify(this.card));
        clone.fake = true;
        clone.id = 10 * this.card.id + i;
        cards.push(clone);
    }
    return { cards: cards }
  },
  created() {
    /*EventBus.$on('mode-changed', value => {
        this.vertical = !value;
        // alert(`hey ${value} vs vertical ${this.vertical}`);
        
    });*/
  }
};
</script>
<style>
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.moving-vertical-card {
  opacity: 0.5;
}
</style>
