<template>
  <div class="h-100 w-100 flex items-center justify-center" >
    <div class="p-2">
      <draggable
        :list="cards"
        :animation="200"
        ghost-class="moving-card"
        group="cards"
        filter=".action-button"
        class="flex"
        tag="ul"
        :disabled="(!horizontal)"
      >
        <card
          v-for="card in cards"
          :key="card.id"
          :vertical="(!horizontal)"
          :card="card"
        ></card>
      </draggable>
    </div>
  
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Card from "./Vertical";
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: () => (true)
    },
    cards: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    draggable,
    Card
  }
};
</script>

<style>
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.moving-card {
  opacity: 0.5;
}
</style>